/**
 * Represents a gallery image
 * @@typedef {Object} GalleryImage
 * @property {string} AssetURI
 */

/**
 * Represents a region
 * @@typedef {Object} Region
 * @property {string} RegionShortName
 * @property {string} RegionName
 */

/**
 * Represents a brand
 * @@typedef {Object} Brand
 * @property {string} Code
 * @property {string} Brand
 */

/**
 * Represents an application
 * @@typedef {Object} Application
 * @property {string} ApplicationName
 */

/**
 * @@typedef {Object} CategoryWithDesign
 * @extends Category
 * @property {Application[]} applications
 * @property {Brand[]} brands
 * @property {Region[]} regions
 * @property {Array<CategoryWithDesign>} Subcategories
 * @property {Array<CategoryWithDesign>} designs
*/

/**
 * Represents a product category.
 * @typedef {Object} Category
 * @property {number} id - The ID of the category.
 * @property {string} Name - The name of the category.
 * @property {string} Description - The description of the category.
 * @property {boolean} IsTopCategory - Indicates whether the category is a top-level category.
 * @property {boolean} IsProductDesign - Indicates whether the category is a product design.
 * @property {string} TechnicalName - The technical name of the category.
 * @property {string} NameSingular - The singular name of the category.
 * @property {boolean} IsVisibleInMenu - Indicates whether the category is visible in the menu.
 * @property {boolean} IsVisibleInAllCategories - Indicates whether the category is visible in all categories.
 * @property {string} DesignSketchAssetURI - The asset URI for the design sketch of the category.
 * @property {string} InstallationSketchAssetURI - The asset URI for the installation sketch of the category.
 * @property {number} xpress_profile - The xpress profile of the category.
 * @property {GalleryImage[]} GalleryImages - The gallery images of the category.
 * @property {string} InstallationSketch - The installation sketch of the category.
 * @property {boolean} IsInchCategory
 */

/**
 * Represents a product attribute.
 * @typedef {Object} Attribute
 * @property {number} id - The ID of the attribute.
 * @property {string} TechnicalName - The technical name of the attribute.
 * @property {string} ValueType - The value type of the attribute.
 * @property {string} UnitOfMeasure - The unit of measure for the attribute.
 * @property {string} IndexPath - The index path of the attribute.
 * @property {boolean} IsFilterable - Indicates whether the attribute is filterable.
 * @property {boolean} IsSortable - Indicates whether the attribute is sortable.
 */

/**
 * Represents a category attribute.
 * @typedef {Object} CategoryAttribute
 * @property {number} id - The ID of the category attribute.
 * @property {Category} Category - The category of the attribute.
 * @property {object} Characteristic - The characteristic of the attribute.
 * @property {string} Label - The label of the attribute.
 * @property {string} ShortLabel - The short label of the attribute.
 * @property {boolean} ShowInDimensionArea - Indicates whether the attribute is shown in the dimension area.
 * @property {boolean} ShowInProductTable - Indicates whether the attribute is shown in the product table.
 * @property {boolean} IsCoreFilter - Indicates whether the attribute is a core filter.
 * @property {Attribute} Attribute - The attribute of the category.
 * @property {boolean} ShowInAttributesArea - Indicates whether the attribute is shown in the attributes area.
 * @property {number} FilterSortOrder - The filter sort order of the attribute.
 * @property {number} ProductTableSortOrder - The product table sort order of the attribute.
 * @property {number} DimensionAreaSortOrder - The dimension area sort order of the attribute.
 * @property {boolean} ShowInSearchText - The show in search text of the attribute.
 * @property {Region} Region - The region of the attribute.
 * @property {boolean} IsFixedColumn - Indicates whether the attribute is a fixed column.
 * @property {boolean} IsFixedBack - Indicates whether the attribute is a fixed back.
 * @property {boolean} IsSortable - Indicates whether the attribute is sortable.
 */

import {useCmsService} from './cms_service'

export const useCategoryDesignService = () => {

  const {getCmsEntityById, getCmsEntityList, getConsolidatedCategory, getCategoryAttributes} = useCmsService()
  let regionGetter
  try {
    regionGetter = useNuxtApp().$globalization
  } catch (_)
  {
    // called from outside nuxt context so we dont need this
    regionGetter = {getRegion: () => null}
  }

  /**
  * gets category by the technical name
  * @param {string} name 
  * @returns {Promise<Category[]>}
  */
  const getCategoryByName = (name) => {
    return getCmsEntityList('categories', null, 0, 1, 'TechnicalName', false, [{field: 'TechnicalName', operator: 'eq', value: name}, {field: 'regions.RegionShortName', operator: 'eq', value: regionGetter.getRegion()}])
  }

  /**
  * gets category by the technical name of the subcategory
  * @param {string} name 
  * @returns {Promise<Category[]>}
  */
  const getCategoryBySubcategoriesTechnicalName = (name) => {
    return getCmsEntityList('categories', null, 0, 1, 'sortIndex', false, [{field: 'Subcategories.TechnicalName', operator: 'eq', value: name}, {field: 'regions.RegionShortName', operator: 'eq', value: regionGetter.getRegion()}])
  }

  /**
  * gets category attributes by the technical name of the category
  * @param {string} name
  * @returns {Promise<CategoryAttribute[]>}
  */
  const getCategoryAttributesByCategoryName = async (name) => {
    const region = regionGetter.getRegion()
    if (name) {
      const res = await getCategoryAttributes(name, region)
      return res.filter((ca) => ca.Region === null || ca.Region.RegionShortName === region)
    }
    return []
  }

  /**
  * Gets the top categories
  * @returns {Promise<Category[]>}
  */
  const getTopCategories = () => {
    return getCmsEntityList('categories', null, 0, 8, 'sortIndex', false, [{field: 'IsTopCategory', operator: 'eq', value: true}, {field: 'regions.RegionShortName', operator: 'eq', value: regionGetter.getRegion()}])
  }

  const getDesigns = () => {
    return getCmsEntityList('categories', null, 0, -1, 'sortIndex', false, [{field: 'IsProductDesign', operator: 'eq', value: 'true'}, {field: 'regions.RegionShortName', operator: 'eq', value: regionGetter.getRegion()}])
  }

  const getCategories = () => {
    return getCmsEntityList('categories', null, 0, -1, 'sortIndex', false, [{field: 'IsProductDesign', operator: 'ne', value: 'true'}, {field: 'regions.RegionShortName', operator: 'eq', value: regionGetter.getRegion()}])
  }

  const getAllCategories = () => {
    return getCmsEntityList('categories', null, 0, -1, 'sortIndex', false, {field: 'regions.RegionShortName', operator: 'eq', value: regionGetter.getRegion()})
  }

  /**
  * 
  * @param {string} region
  * @param {boolean} showInchAssortmentOnly 
  * @returns {Promise<CategoryWithDesign[]>}
  */
  const getCategoriesWithDesigns = (region, showInchAssortmentOnly = false) => {
    let filters = [{field: 'Region', value: region || regionGetter.getRegion()}]
    if (showInchAssortmentOnly) {
      filters.push({field: 'IsInchCategory', value: true})
    }
    return getCmsEntityList('categories/designs', null, 0, -1, 'sortIndex', false, filters)
  }

  const getCategoryWithDesign = (technicalName) => {
    return getConsolidatedCategory(technicalName, regionGetter.getRegion())
  }

  const getCategoryContentsByCategoryName = (technicalNames, lang) => {
    if (technicalNames && Array.isArray(technicalNames)) {
      return getCmsEntityList('category-contents', lang, 0, -1, null, null, {field: 'Category.TechnicalName', operator: 'in', value: technicalNames})
    }
    return []
  }

  const getCategoriesByTechnicalNames = (technicalNames) => {
    if (technicalNames && Array.isArray(technicalNames) && technicalNames.length > 0) {
      return getCmsEntityList('categories', null, 0, -1, 'sortIndex', false, [{field: 'TechnicalName', operator: 'in', value: technicalNames}, {field: 'regions.RegionShortName', operator: 'eq', value: regionGetter.getRegion()}])
    }
    return []
  }

  const getCategoryContentsByUrl = async function (url, lang) {
    if (url && url !== '' && lang && lang !== '') {
      try {
        return await getCmsEntityById('category-contents/url', url, lang)
      } catch {
        return null
      }
    }
    return null
  }

  return {
    getCategoryByName,
    getCategoryBySubcategoriesTechnicalName,
    getCategoryAttributesByCategoryName,
    getTopCategories,
    getDesigns,
    getCategories,
    getAllCategories,
    getCategoriesWithDesigns,
    getCategoryWithDesign,
    getCategoryContentsByCategoryName,
    getCategoriesByTechnicalNames,
    getCategoryContentsByUrl
  }
}
